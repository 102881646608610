// Modules
import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import { CustomTooltip, IconButton, TableRow } from "../../../../../components";
// Helpers
import { parseTitle } from "../../helpers/rowHelper";
// Translation
import translation from "../../../../../config/translation";
// Styles
import styles from "./KeywordRow.css";

class CustomEditIconButton extends PureComponent {
    static propTypes = {
        handleTooltipOnHover: PropTypes.func.isRequired,
        openEditPopup: PropTypes.func.isRequired
    };

    render() {
        const { handleTooltipOnHover, openEditPopup } = this.props;

        return (
            <IconButton
                defaultClassName="icon-edit"
                onClick={event => {
                    event.target && event.target.blur();
                    handleTooltipOnHover && handleTooltipOnHover(false);
                    openEditPopup && openEditPopup();
                }}
                style={styles.icon}
                type={["default"]}
            />
        );
    }
}

class KeywordRow extends PureComponent {
    static propTypes = {
        containerStyle: PropTypes.object.isRequired,
        entry: PropTypes.object.isRequired,
        openEditPopup: PropTypes.func.isRequired
    };

    state = { tooltipOpen: false };

    renderRow = row => {
        const keyword = get(row, "keyword", undefined);

        const offers = get(row, "offers_data", [])
            .sort((a, b) =>
                get(a, "title", "").localeCompare(get(b, "title", ""))
            )
            .map(
                offer =>
                    get(offer, "title", undefined) ||
                    get(offer, "display_title", undefined)
            );

        return (
            <Fragment>
                <div
                    style={{
                        ...styles.text,
                        ...styles.blueBoxContainer
                    }}
                >
                    {keyword}
                </div>
                {offers && offers.length > 0 ? (
                    <div style={styles.innerContainer}>
                        {offers.map((offer, index) => (
                            <div
                                dangerouslySetInnerHTML={parseTitle(offer)}
                                key={index}
                                style={{
                                    ...styles.text,
                                    ...styles.boxContainer
                                }}
                            />
                        ))}
                    </div>
                ) : (
                    <div />
                )}
                {this.renderEditActionButton()}
            </Fragment>
        );
    };

    handleTooltipOnHover = onHover => {
        this.setState({ tooltipOpen: onHover });
    };

    renderEditActionButton = () => {
        const { tooltipOpen } = this.state;
        const { openEditPopup } = this.props;

        return (
            <CustomTooltip
                onClose={() => this.handleTooltipOnHover(false)}
                onOpen={() => this.handleTooltipOnHover(true)}
                open={tooltipOpen}
                placement="top"
                title={translation.offersKeywordsTagging.edit}
            >
                <span>
                    <CustomEditIconButton
                        handleTooltipOnHover={this.handleTooltipOnHover}
                        openEditPopup={openEditPopup}
                    />
                </span>
            </CustomTooltip>
        );
    };

    render() {
        const { containerStyle, entry } = this.props;

        return (
            <TableRow
                containerStyle={{
                    ...containerStyle,
                    ...styles.rowContainer
                }}
                id={get(entry, "keyword", "")}
            >
                {this.renderRow(entry)}
            </TableRow>
        );
    }
}

export default KeywordRow;
