// Modules
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash.get";
// Components
import {
    CustomRadioButton,
    CustomTooltip,
    IconButton,
    InfiniteScrollLoading,
    TableRow
} from "../../../../../../../components/";
import InfiniteScroll from "react-infinite-scroller";
import { ClassificationTag } from "../../../../../components/";
// Actions
import {
    closeViewPopup,
    openViewPopup,
    updateViewPopupState
} from "../../../../../../../components/ViewPopup/ViewPopup.ducks";
import {
    getGFIOfferEnrolments,
    saveOfferDetails
} from "../../../../../Offers/components/OfferDetails/OfferDetails.ducks";
// Helpers
import {
    getScrollPosition,
    openOfferDetailsView,
    parseTitle,
    renderDetailsButton
} from "../../../../helpers/rowHelper";
import { validateLink } from "../../../../../helpers/links";
// Tranlation
import translation from "../../../../../../../config/translation";
// Styles
import styles from "./OfferMergeBody.css";

class OfferMergeBody extends PureComponent {
    static propTypes = {
        closeViewPopup: PropTypes.func,
        getGFIOfferEnrolments: PropTypes.func,
        getSearchQuery: PropTypes.func,
        handleLoadMoreOffers: PropTypes.func,
        offers: PropTypes.array,
        offerToMergeURL: PropTypes.string,
        openViewPopup: PropTypes.func,
        saveOfferDetails: PropTypes.func,
        suggestions: PropTypes.array,
        tab: PropTypes.string,
        totalCount: PropTypes.number,
        updateViewPopupState: PropTypes.func,
        viewPopupState: PropTypes.object
    };

    handleRowSelection = selection => {
        const { updateViewPopupState, viewPopupState } = this.props;
        updateViewPopupState &&
            updateViewPopupState(
                Object.assign({}, viewPopupState, {
                    selection
                })
            );
    };

    handleOpenOfferDetailsView = offer => {
        const {
            closeViewPopup,
            getGFIOfferEnrolments,
            offerToMergeURL,
            openViewPopup,
            saveOfferDetails
        } = this.props;

        const scrollPosition = getScrollPosition("view-popup-body");

        const offerURL = offerToMergeURL || get(offer, "url", undefined);
        const rowElement = offerURL && document.getElementById(offerURL);
        const rowHeight = rowElement && rowElement.clientHeight;
        const bodyHeight = `calc(100vh - ${332 + rowHeight}px)`;
        const style = {
            header: styles.header,
            body: {
                height: bodyHeight,
                minHeight: "100px"
            },
            container: {
                gridTemplateRows: `96px minmax(100px, ${bodyHeight})`,
                height: `calc(100vh - ${236 + (rowHeight || 0)}px)`,
                overflow: "auto",
                top: `${164 + (rowHeight || 0)}px`
            }
        };

        openOfferDetailsView({
            closeViewPopup,
            extraHeader: (
                <div
                    style={styles.extraHeader}
                >{`${translation.offersClassification.selectOfferToMerge}:`}</div>
            ),
            getGFIOfferEnrolments,
            offer,
            openViewPopup,
            saveOfferDetails,
            scrollPosition,
            style
        });
    };

    renderExternalInfoButton = externalLink => {
        const externalLinkUrl = validateLink(externalLink);

        return (
            <CustomTooltip
                placement="top"
                title={translation.offersClassification.externalLink}
            >
                <span>
                    <IconButton
                        defaultClassName={"icon-info-file"}
                        onClick={() => {
                            window.open(externalLinkUrl, "_blank");
                        }}
                        style={styles.icon}
                        type={["default"]}
                    />
                </span>
            </CustomTooltip>
        );
    };

    renderRow = (offer, index) => {
        const { viewPopupState } = this.props;
        const selection = get(viewPopupState, "selection", undefined);

        const offerTitle =
            get(offer, "title", undefined) ||
            get(offer, "display_title", undefined);

        const isChecked =
            get(offer, "_id", undefined) === get(selection, "_id", undefined);
        const externalLink = get(offer, "external_link", undefined);
        const classification = get(offer, "classification", undefined);

        return (
            <TableRow
                containerStyle={{
                    ...styles.rowContainer,
                    ...(externalLink
                        ? styles.rowContainerWithExternalLink
                        : {}),
                    ...(isChecked ? styles.rowContainerSelected : {})
                }}
                disableOnHover={isChecked}
                id={get(offer, "url", "")}
                key={index}
            >
                <CustomRadioButton
                    checked={isChecked}
                    checkmarkDefaultClassName={isChecked ? "icon-check" : null}
                    defaultClassName={"offer-merge-radio-button"}
                    label={
                        <div
                            dangerouslySetInnerHTML={parseTitle(offerTitle)}
                            style={styles.text}
                        />
                    }
                    onChange={() => this.handleRowSelection(offer)}
                    style={styles.radio}
                />
                {classification ? (
                    <ClassificationTag
                        classification={classification}
                        style={styles.alignRight}
                    />
                ) : (
                    <div />
                )}
                {renderDetailsButton({
                    openDetailView: () => this.handleOpenOfferDetailsView(offer)
                })}
                {externalLink && this.renderExternalInfoButton(externalLink)}
                {isChecked && <div style={styles.marker} />}
            </TableRow>
        );
    };

    render() {
        const {
            getSearchQuery,
            handleLoadMoreOffers,
            offers,
            suggestions,
            totalCount
        } = this.props;

        const searchQuery = getSearchQuery && getSearchQuery();
        const displaySuggestions = !searchQuery || searchQuery.length === 0;

        return (
            <div style={styles.container}>
                {suggestions && suggestions.length > 0 && displaySuggestions && (
                    <Fragment>
                        <div style={styles.section}>
                            {translation.offersClassification.offerSuggestions}
                        </div>
                        <div style={styles.sectionMargin}>
                            {suggestions &&
                                suggestions.map((offer, index) =>
                                    this.renderRow(offer, index)
                                )}
                        </div>
                    </Fragment>
                )}
                <div style={styles.section}>
                    {translation.offersClassification.allOffers}
                </div>
                <InfiniteScroll
                    className="infinite-scroll"
                    hasMore={totalCount > (offers ? offers.length : 0)}
                    initialLoad={true}
                    loader={<InfiniteScrollLoading key="loading" />}
                    loadMore={handleLoadMoreOffers}
                    threshold={500}
                    useWindow={false}
                    style={styles.infineScroll}
                    getScrollParent={() =>
                        document.getElementsByClassName("view-popup-body")[0]
                    }
                >
                    {offers &&
                        offers.map((offer, index) =>
                            this.renderRow(offer, index)
                        )}
                </InfiniteScroll>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const tab = get(props, "tab", undefined);

    return {
        offers: get(
            state,
            "offersClassification.offersByTab.approved.offers",
            []
        ),
        suggestions: get(
            state,
            `offersClassification.offersByTab.${tab}.suggestions`,
            []
        ),
        totalCount: get(
            state,
            "offersClassification.offersByTab.approved.totalCount",
            undefined
        ),
        viewPopupState: get(state, "viewPopup.state", undefined)
    };
};

const mapDispatchToProps = {
    closeViewPopup,
    getGFIOfferEnrolments,
    openViewPopup,
    saveOfferDetails,
    updateViewPopupState
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferMergeBody);
